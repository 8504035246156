// tslint:disable: semicolon
import React, { ChangeEvent, Component, FormEvent } from 'react';
import { IChatMessageProps } from '../../../models';

class NameForm extends Component<IChatMessageProps, { error: boolean }> {
  chatNameFormWrapper: React.RefObject<HTMLInputElement>;
  constructor(props: IChatMessageProps) {
    super(props);
    this.state = {
      error: false,
    };
    this.chatNameFormWrapper = React.createRef();
  }

  componentDidMount() {
    this.chatNameFormWrapper.current.focus();
  }
  onChange = (e: ChangeEvent) => {
    e.preventDefault();
    this.setState({
      error: false,
    });
  };
  onNameSubmit = (e: FormEvent) => {
    e.preventDefault();
    const { onSubmit } = this.props;
    const { value } = this.chatNameFormWrapper.current;
    if (!value) {
      this.setState({
        error: true,
      });
      return;
    }
    localStorage.setItem('chatUserName', value);
    onSubmit();
  };
  render() {
    const { error } = this.state;
    return (
      <div className="grid gap-2 ask-username-form mb-2 chat-user-name">
        <p>Please enter your name below to join our chat.</p>
        <form onSubmit={this.onNameSubmit}>
          <label htmlFor="name" className="nopadding">
            Your name
          </label>
          <input
            id="name"
            type="text"
            ref={this.chatNameFormWrapper}
            onChange={this.onChange}
            className="mb-2"
          />
          {error && (
            <div className="login-form-error">
              {/* <img src={ErrorIcon} alt="error" /> Name is required */}
            </div>
          )}
          <button type="submit" className="chat-submit-btn float-right">
            Submit
          </button>
        </form>
      </div>
    );
  }
}

export default NameForm;
