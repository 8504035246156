import {
  IMarkRsvpResponse,
  IEventCountResponse,
  IMarkAttendance,
  IMarkAttendanceResponse,
} from './../../models/event';
import { http } from '../common/http.service';
import { AxiosResponse } from 'axios';
import {
  PaginatedResponse,
  IEvent,
  IEventDetailResponse,
  IMarkRsvp,
  IEventRating,
} from '../../models';
import {
  CURRENT_DATE,
  BUILDING_NAME,
  PARAM,
  RECORDS_PAGE,
  RECORDS_LIMIT,
} from './../../constants';

export const getEventsApi = async (
  buildingName: string = BUILDING_NAME,
  start_date: string = CURRENT_DATE,
  param: string = PARAM,
  page: number = RECORDS_PAGE,
  limit: number = RECORDS_LIMIT
): Promise<PaginatedResponse<IEvent>> => {
  return http
    .get(
      `event-list/${buildingName}?start_date=${start_date}&param=${param}&page=${page}&limit=${limit}`,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<PaginatedResponse<IEvent>>) => {
      return response.data;
    });
};

export const getFilterEventsApi = async (
  buildingCode: string = BUILDING_NAME,
  filter_date: string = CURRENT_DATE,
  param: string = PARAM,
  page: number = RECORDS_PAGE,
  limit: number = RECORDS_LIMIT
): Promise<PaginatedResponse<IEvent>> => {
  return http
    .get(
      `event-list/${buildingCode}?start_date=${filter_date}&page=${page}&param=${param}&limit=${limit}&filter_type=1`,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<PaginatedResponse<IEvent>>) => {
      return response.data;
    });
};

export const getEventDetailApi = async (
  eventId: number,
  buildingName: string = BUILDING_NAME
): Promise<IEventDetailResponse> => {
  return http
    .get(`/details/${eventId}?building_code=${buildingName}`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<IEventDetailResponse>) => {
      return response.data;
    });
};

export const eventRatingApi = async (
  data: IEventRating,
  eventId: number
): Promise<IEventDetailResponse> => {
  return http
    .post(`/event-action/rate/${eventId}`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<IEventDetailResponse>) => {
      return response.data;
    });
};

export const getLiveStreamDetailApi = async (
  eventId: number,
  buildingName: string = BUILDING_NAME,
  signedToken: string = '',
  idToken: string = ''
): Promise<IEventDetailResponse> => {
  return http
    .get(
      `/livestream/${eventId}?building_code=${buildingName}${
        signedToken && `&signed_token=${signedToken}`
      }${idToken && `&identity_token=${idToken}`}`,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<IEventDetailResponse>) => {
      return response.data;
    });
};

export const markRsvpApi = async (
  data: IMarkRsvp,
  eventId: number
): Promise<IMarkRsvpResponse> => {
  return http
    .post(`/event-action/rsvp/${eventId}`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<IMarkRsvpResponse>) => {
      return response.data;
    });
};

export const markUnRsvpApi = async (
  eventId: number
): Promise<IMarkRsvpResponse> => {
  return http
    .delete(`/event-action/rsvp/${eventId}`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<IMarkRsvpResponse>) => {
      return response.data;
    });
};

export const eventDateCountApi = (
  buildingName: string,
  start_date: string
): Promise<IEventCountResponse> => {
  return http
    .get(`/event-count/${buildingName}?start_date=${start_date}`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<IEventCountResponse>) => {
      return response.data;
    });
};

export const autoMarkAttendanceApi = async (
  eventId: number,
  data: IMarkAttendance
): Promise<IMarkAttendanceResponse> => {
  return http
    .post(`/event-action/auto-attendance/${eventId}/`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<IMarkAttendanceResponse>) => {
      return response.data;
    });
};
