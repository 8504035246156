import { RECORDS_PAGE, TOAST_MESSAGES } from './../../constants/index';
import {
  eventRatingApi,
  getEventDetailApi,
  getEventsApi,
  markRsvpApi,
  markUnRsvpApi,
  getLiveStreamDetailApi,
  getFilterEventsApi,
  eventDateCountApi,
  autoMarkAttendanceApi,
} from './events.service';
import * as eventTypes from './events.constant';
import * as loadingTypes from '../loader/loader.constant';
import * as toastTypes from '../toast/toast.constant';
import { IEventRating, IMarkRsvp, IEvent, IMarkAttendance } from '../../models';

export const getEvents = (
  buildingName: string,
  start_date: string,
  param: string,
  page: number = RECORDS_PAGE
) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const events = await getEventsApi(buildingName, start_date, param, page);
  if (events.data) {
    dispatch({
      type: eventTypes.GET_EVENTS,
      payload: events.data?.['events'],
      eventTotal: events.data?.['total'],
      page,
    });
  }
  dispatch({
    type: loadingTypes.LOADING_STOPED,
  });
};

export const getFilteredEvents = (
  buildingCode: string,
  filter_date,
  param: string,
  page?: number
) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const events = await getFilterEventsApi(
    buildingCode,
    filter_date,
    param,
    page
  );
  if (events.data) {
    dispatch({
      type: eventTypes.GET_FILTER_EVENTS,
      payload: events.data?.['events'],
      eventTotal: events.data?.['total'],
    });
  }
  dispatch({
    type: loadingTypes.LOADING_STOPED,
  });
  return events.data?.['total'];
};

export const clearEvents = () => async (dispatch) => {
  dispatch({
    type: eventTypes.CLEAR_EVENTS,
  });
};

export const getEventDetail = (eventId: number, buildingName: string) => async (
  dispatch
) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const detail = await getEventDetailApi(eventId, buildingName);
  if (detail.data) {
    dispatch({
      type: eventTypes.GET_EVENT_DETAIL,
      payload: detail.data,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const markRsvped = (
  data: IMarkRsvp,
  eventId: number,
  page?: number
) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const detail = await markRsvpApi(data, eventId);
  if (detail?.['data']) {
    if (detail?.['data']?.['code'] === 400) {
      dispatch({
        type: toastTypes.UDPATE_TOAST,
        toastMessage: detail?.['data']?.['message'],
      });
    } else {
      dispatch({
        type: eventTypes.MARK_UNMARK_RSVP,
        payload: detail?.['data'],
        page,
        eventId,
      });
    }
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const markUnRsvped = (eventId: number, tab?: string) => async (
  dispatch
) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const detail = await markUnRsvpApi(eventId);
  if (detail?.['data']) {
    dispatch({
      type: eventTypes.MARK_UNMARK_RSVP,
      payload: detail?.['data'],
      eventId,
      tab,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};
export const markLivestreamRsvped = (
  data: IMarkRsvp,
  eventId: number
) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const detail = await markRsvpApi(data, eventId);
  if (detail?.['data']) {
    dispatch({
      type: eventTypes.MARK_LIVESTREAM_UNMARK_RSVP,
      payload: detail?.['data'],
      eventId,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const markLivestreamUnRsvped = (
  eventId: number,
  page?: number
) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const detail = await markUnRsvpApi(eventId);
  if (detail?.['data']) {
    dispatch({
      type: eventTypes.MARK_LIVESTREAM_UNMARK_RSVP,
      payload: detail?.['data'],
      page,
      eventId,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const getLiveStreamDetail = (
  eventId: number,
  buildingName: string,
  signedToken?: string,
  idToken?: string
) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const detail = await getLiveStreamDetailApi(
    eventId,
    buildingName,
    signedToken,
    idToken
  );
  if (detail.data) {
    dispatch({
      type: eventTypes.GET_LIVESTREAM_DETAIL,
      payload: detail.data,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const eventRating = (data: IEventRating, eventId: number) => async (
  dispatch
) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const detail = await eventRatingApi(data, eventId);
  if (detail.data) {
    dispatch({
      type: toastTypes.SUCCESS_TOAST,
      toastMessage: TOAST_MESSAGES.rating.message,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const getEventDateCount = (
  buildingName: string,
  start_date: string
) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const eventDateCount = await eventDateCountApi(buildingName, start_date);
  if (eventDateCount.data) {
    dispatch({
      type: eventTypes.GET_EVENT_DATE_COUNT,
      payload: eventDateCount.data,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const markedEventStart = (
  data: IEvent,
  eventId: number,
  page?: number
) => async (dispatch) => {
  dispatch({
    type: eventTypes.MARK_EVENT_START,
    payload: data,
    page,
    eventId,
  });
};

export const autoMarkAttendance = (
  eventId: number,
  data: IMarkAttendance
) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const detail = await autoMarkAttendanceApi(eventId, data);
  if (detail?.['data']) {
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};
