// tslint:disable: semicolon
// tslint:disable: no-shadowed-variable
import React from 'react';
import MessageForm from './MessageForm';
import MessageList from './MessageList';
import NameForm from './NameForm';
import {
  getChatTokenApi,
  updateChatMessageApi,
} from '../../../redux/livestream/livestream.service';
import './Chat.scss';
import { getImageUrl } from '../../../utils';
import { IChatMessageProps } from '../../../models';
import { EN } from '../../../constants';
// tslint:disable-next-line: no-var-requires
const TwilioChat = require('twilio-chat');
class Chat extends React.Component<IChatMessageProps, any> {
  state = {
    messages: [],
    username: null,
    channel: null,
    isTeacher: false,
    loading: true,
    text: '',
    askUserName: false,
  };

  componentDidMount = async () => {
    const { pastEvent, channelName } = this.props;
    if (!pastEvent) {
      let token = '';
      try {
        token = await this.getToken();
        if (token) {
          const client = await TwilioChat.Client.create(token);
          client.on('tokenAboutToExpire', async () => {
            const token = await this.getToken();
            client.updateToken(token);
          });

          client.on('tokenExpired', async () => {
            const token = await this.getToken();
            client.updateToken(token);
          });

          client.on('channelJoined', async (channel) => {
            // getting list of all messages since this is an existing channel
            const oldMessages = [];
            const messages = await channel.getMessages();
            const totalMessages = messages.items.length;
            if (totalMessages > 0 && channel.sid === this.state?.channel?.sid) {
              for (let i = 0; i < totalMessages; i++) {
                const message = messages.items[i];
                oldMessages.push(message);
              }
              this.setState({
                messages: [...oldMessages],
                loading: false,
              });
            }
          });
          try {
            const channel = await client.getChannelByUniqueName(
              `${channelName}`
            );
            await this.joinChannel(channel);
            this.setState({ channel, loading: false });
          } catch {
            try {
              const channel = await client.createChannel({
                uniqueName: channelName,
                friendlyName: `${channelName} Chat`,
              });
              await this.joinChannel(channel);
              this.setState({ channel, loading: false });
            } catch {
              throw new Error(
                'unable to create channel, please reload this page'
              );
            }
          }
        }
      } catch {
        throw new Error('unable to get token, please reload this page');
      }
    }
  };

  getToken = async () => {
    const { userToken } = this.props;
    if (!userToken && !localStorage.getItem('chatUserName')) {
      this.setState({
        askUserName: true,
      });
      return;
    }
    const token = await getChatTokenApi();
    localStorage.setItem('chatToken', token.jwt);
    this.setState({ chatToken: token });
    this.setState({ username: token.identity });
    return token.jwt;
  };

  createChatClient = async (token) => {
    return await TwilioChat.create(token.jwt);
  };

  joinChannel = async (channel) => {
    if (channel.channelState.status !== 'joined') {
      await channel.join();
    }
    channel.on('messageAdded', this.handleMessageAdded);
    channel.on('messageUpdated', this.setNewMessages);
  };
  handleMessageAdded = (message) => {
    const { messages } = this.state;
    this.setState({
      messages: !!messages ? [...messages, message] : [message],
    });
  };
  findMessageInState = (text, messageId) => {
    const { messages } = this.state;

    for (const i in messages) {
      if (messages[i].sid === messageId) {
        messages[i].body = text;
        break; // Stop this loop, we found it!
      }
    }
    return messages;
  };
  setNewMessages = (message) => {
    const newMessages = this.findMessageInState(message.body, message.sid);
    this.setState({
      messages: newMessages,
    });
  };
  onSubmit = () => {
    this.setState({
      askUserName: false,
    });
    this.componentDidMount();
  };
  sendMessage = (text) => {
    const { channel } = this.state;

    if (text && String(text).trim()) {
      this.setState({ loading: true });
      if (channel) {
        channel.sendMessage(text);
      }
      this.setState({ text: '', loading: false });
    }
  };

  onUpdateMessage = async (text, messageId) => {
    const {
      channel: { sid },
    } = this.state;
    try {
      const data = {
        channelId: sid,
        messageId,
        body: text,
      };
      await updateChatMessageApi(data);
    } catch (error) {}
  };
  render() {
    const {
      heading,
      headingStyleClass,
      sectionStyleClass,
      btnText = 'Send',
      pastEvent,
    } = this.props;
    const { loading, messages, askUserName } = this.state;
    return (
      <div className={sectionStyleClass}>
        <div className={headingStyleClass}>{heading}</div>
        <div className="chat-heading-content">
          <h3 className="text-F18 font-brandonBold text-Color_22">
            {EN.chat.title}
          </h3>
          <span className="text-F16 font-brandonRegular">
            {EN.chat.subHeading}
          </span>
        </div>
        {askUserName || pastEvent ? (
          pastEvent ? (
            <p className="chat-disabled">Chat is disabled</p>
          ) : (
            <NameForm onSubmit={this.onSubmit} />
          )
        ) : loading ? (
          <div className="chat-loader">
            <img
              alt="loader"
              src={getImageUrl('ConsumerApp/loader-icon.gif', true)}
            />
            <p>Connecting chat...</p>
          </div>
        ) : (
          <MessageList
            messages={messages}
            username={this.state.username}
            onUpdateMessage={this.onUpdateMessage}
          />
        )}
        <MessageForm
          onMessageSend={this.sendMessage}
          btnText={btnText}
          askUserName={askUserName}
          pastEvent={pastEvent}
          disabled={loading}
        />
      </div>
    );
  }
}

export default Chat;
