import React, { useState } from 'react';
import {
  PrimaryLabel,
  VideoPlayer,
  SlideDrawer,
} from '@alfredclub/hom-planner-theme';
import { useParams } from 'react-router-dom';
import {
  checkPastEvent,
  getDayAndTime,
  getImageUrl,
  isMobileCheck,
  getSubDomain,
  getIdFromVimeoURL,
  getIdFromYouTubeUrl,
} from '../../utils';
import './LiveStream.scss';
import '../Events/EventListing.scss';
import { useSelector, useDispatch } from 'react-redux';
import {
  getLiveStreamDetail,
  IEventState,
  markLivestreamRsvped,
  markLivestreamUnRsvped,
} from '../../redux/events';
import {
  EVENT_DAY_FORMAT,
  EVENT_TIME_FORMAT,
  BUILDING_NAME,
} from '../../constants';
import QuestionBox from './QuestionBox';
import { useFetch } from '../../hooks';
import { ILoaderState } from '../../redux/loader';
import CommonLoader from '../Common/CommonLoader';
import EventRating from '../Events/EventRating';
import Chat from '../Common/TwilioChat';
import { VIMEO_EMBED, YOUTUBE_EMBED } from '../../constants/index';

const LiveStream = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const eventId = parseInt(params['eventId'], 10);
  const subDomain = getSubDomain();
  const queryParams = new URLSearchParams(document.location.search);
  const buildingCode = queryParams.get('building-code');
  const idToken = queryParams.get('identityToken');
  const signedToken = queryParams.get('signedToken');
  useFetch(getLiveStreamDetail, [
    eventId,
    buildingCode,
    signedToken ? signedToken : '',
    idToken ? idToken : '',
  ]);
  const [showEventFeedBack, setEventFeedBack] = useState<boolean>(false);
  const { liveStreamDetail } = useSelector(
    (state: { events: IEventState }) => state.events
  );
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );

  const [showTimer, setShowTimer] = useState<boolean>(
    !checkPastEvent(liveStreamDetail?.details?.startDatetime)
  );

  const markRsvp = async () => {
    if (liveStreamDetail?.['details']) {
      const data = {
        buildingCode: subDomain ?? BUILDING_NAME,
        reference: 'app',
      };

      await dispatch(
        markLivestreamRsvped(data, liveStreamDetail?.['details']?.['id'])
      );
    }
  };

  const unMarkRsvp = async () => {
    if (liveStreamDetail?.['details']) {
      await dispatch(
        markLivestreamUnRsvped(liveStreamDetail?.['details']?.['id'])
      );
    }
  };

  const getIframeSource = (url: string) => {
    if (url) {
      if (url.includes('vimeo')) {
        if (url.includes('event')) {
          return `${url}/embed`;
        } else {
          return `${VIMEO_EMBED}${getIdFromVimeoURL(url)}`;
        }
      } else if (url.includes('whereby')) {
        return `${url}?embed&chat=off&iframeSource=hom`;
      } else if (url.includes('youtube') || url.includes('youtu.be')) {
        return getIdFromYouTubeUrl(url)
          ? `${YOUTUBE_EMBED}${getIdFromYouTubeUrl(url)}`
          : '';
      }
    }
    return '';
  };

  const timerToggle = () => {
    setShowTimer(false);
  };
  return (
    <>
      <SlideDrawer
        show={showEventFeedBack}
        showCloseIcon={true}
        closePane={() => setEventFeedBack(false)}
        showIconClass="p-8 lg:p-0"
        showIconDivClass="pt-0 pb-0 md:pt-4"
      >
        {showEventFeedBack && (
          <EventRating
            eventId={eventId}
            closePane={() => setEventFeedBack(false)}
          />
        )}
      </SlideDrawer>
      {loading ? (
        <CommonLoader loading={loading} />
      ) : (
        <div className="px-8 lg:px-24 live-wrapper">
          <div
            className={`grid grid-cols-1 lg:grid-cols-3 gap-2 chat-grid lg:gap-4 pt-${
              liveStreamDetail?.details?.rsvp ? '6' : '16'
            }`}
          >
            <div className="col-span-2">
              <>
                {liveStreamDetail?.details?.rsvp && (
                  <div className="bottom-space">
                    {!checkPastEvent(
                      liveStreamDetail?.details?.startDatetime
                    ) && (
                      <>
                        <PrimaryLabel
                          labelText="Thanks for RSVPing to this event. We’ve reserved a spot for you!"
                          className="need-to-know-question"
                        />
                        <span
                          onClick={() => {
                            unMarkRsvp();
                          }}
                        >
                          <PrimaryLabel
                            labelText="unRSVP"
                            className="unrsvp-text"
                          />
                        </span>
                      </>
                    )}
                    {checkPastEvent(liveStreamDetail?.details?.endDatetime) && (
                      <span
                        className="cursor-pointer"
                        onClick={() => setEventFeedBack(true)}
                      >
                        <span className="event-feedback"> Give Feedback</span>
                        <span className="event-feedback pr-1">
                          <img
                            src={getImageUrl(
                              'ConsumerApp/give-feedback.svg',
                              true
                            )}
                            alt="feedback"
                          />
                        </span>
                      </span>
                    )}
                  </div>
                )}
                <VideoPlayer
                  showTimer={showTimer}
                  isPastEvent={checkPastEvent(
                    liveStreamDetail?.details?.endDatetime
                  )}
                  iFrameSource={getIframeSource(liveStreamDetail?.tenantUrl)}
                  videoType=""
                  startTime={liveStreamDetail?.details?.startDatetime}
                  endTime={liveStreamDetail?.details?.endDatetime}
                  videoImage=""
                  onClickRsvp={() => {
                    markRsvp();
                  }}
                  rsvpd={
                    liveStreamDetail?.details?.rsvp ||
                    liveStreamDetail?.attendee
                  }
                  className={`${isMobileCheck() && 'video-player-mobile'}`}
                  timerToggle={timerToggle}
                  eventTimeZone={
                    liveStreamDetail?.['details']?.['regionTimezone']
                  }
                />
              </>
            </div>
            <div
              className={`chat-wrapper mt-${
                liveStreamDetail?.details?.rsvp
                  ? checkPastEvent(liveStreamDetail?.details?.startDatetime)
                    ? '6'
                    : '12'
                  : '0'
              }`}
            >
              <Chat
                isPastEvent={checkPastEvent(
                  liveStreamDetail?.details?.endDatetime
                )}
                userToken={localStorage.getItem('access')}
                channelName={`${liveStreamDetail?.[
                  'id'
                ].toString()}${liveStreamDetail?.details?.['id'].toString()}`}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-0 md:gap-4 pt-6 description-wrapper">
            <div className="col-span-2">
              <PrimaryLabel
                labelText={`${
                  liveStreamDetail?.['details']?.['virtual']
                    ? `Online Event`
                    : `Venue Event`
                }`}
                className="block text-base text-color"
              />
              <PrimaryLabel
                labelText={`${liveStreamDetail?.['details']?.['displayName']}`}
                className="sub-header block h-auto event-title"
              />
              <div className="event-info inline-grid gap-y-4">
                <div className="inline-flex">
                  <img
                    src={getImageUrl('ConsumerApp/dayonmodal.svg', true)}
                    alt="day"
                  />
                  <span className="pl-4 font-brandonMedium text-F17">
                    {liveStreamDetail?.['details']
                      ? getDayAndTime(
                          liveStreamDetail?.['details']?.['startDatetime'],
                          EVENT_DAY_FORMAT,
                          liveStreamDetail?.details?.virtual
                            ? liveStreamDetail?.['details']?.['regionTimezone']
                            : liveStreamDetail?.['details']?.['eventTimezone'][
                                'zoneValue'
                              ]
                        )
                      : ''}
                  </span>
                </div>
                <div className="inline-flex">
                  <img
                    src={getImageUrl('ConsumerApp/Day_timeeventcard.svg', true)}
                    alt="time"
                  />
                  <span className="pl-4 font-brandonMedium text-F17">
                    {getDayAndTime(
                      liveStreamDetail?.['details']?.['startDatetime'],
                      EVENT_TIME_FORMAT,
                      liveStreamDetail?.details?.virtual
                        ? liveStreamDetail?.['details']?.['regionTimezone']
                        : liveStreamDetail?.['details']?.['eventTimezone'][
                            'zoneValue'
                          ]
                    )}{' '}
                    -{' '}
                    {getDayAndTime(
                      liveStreamDetail?.['details']?.['endDatetime'],
                      EVENT_TIME_FORMAT,
                      liveStreamDetail?.details?.virtual
                        ? liveStreamDetail?.['details']?.['regionTimezone']
                        : liveStreamDetail?.['details']?.['eventTimezone'][
                            'zoneValue'
                          ]
                    )}{' '}
                    {liveStreamDetail?.details?.virtual
                      ? liveStreamDetail?.['details']?.[
                          'regionTimezoneAbbrevation'
                        ]
                      : liveStreamDetail?.['details']?.['eventTimezone'][
                          'shortName'
                        ]}
                  </span>
                </div>
                <div className="inline-flex pb-6">
                  <img
                    src={getImageUrl('ConsumerApp/Venueeventcard.svg', true)}
                    alt="venue"
                  />
                  <span className="pl-4 font-brandonMedium text-F17">
                    {liveStreamDetail?.['details']?.['virtual']
                      ? `Online - Streamed on App`
                      : ``}
                  </span>
                </div>
              </div>
              <div className="QuestionBox-description border-height liveStream-details">
                <PrimaryLabel
                  labelText="About the event"
                  className="block sub-header mb-4 about-event-title header-font-size"
                />
                <PrimaryLabel
                  labelText={liveStreamDetail?.['eventDescription']}
                  className="about-sub-header"
                />
              </div>
              <div className="QuestionBox-description liveStream-details">
                <PrimaryLabel
                  labelText="About the instructor"
                  className="block sub-header mb-4 about-event-title header-font-size"
                />
                <div className="block lg:inline-flex">
                  {liveStreamDetail?.['instructorDetails']?.['image'] && (
                    <img
                      src={getImageUrl(
                        liveStreamDetail?.['instructorDetails']?.['image']
                      )}
                      alt="img"
                      className="block lg:hidden w-12 h-12 ml-0 mb-4"
                    />
                  )}
                  <PrimaryLabel
                    labelText={
                      liveStreamDetail?.['instructorDetails']?.[
                        'description'
                      ] ?? 'No description added!'
                    }
                    className="inline-flex w-full about-sub-header"
                  />
                  {liveStreamDetail?.['instructorDetails']?.['image'] && (
                    <img
                      src={getImageUrl(
                        liveStreamDetail?.['instructorDetails']?.['image']
                      )}
                      alt="img"
                      className="hidden lg:block w-24 h-20 ml-4"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="pt-4">
              <QuestionBox />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LiveStream;
